import { Role } from "sx-shared-core";

export const items = [
  {
    icon: "mdi-account-group",
    roles: [Role.ADMIN, Role.ACCOUNT_MANAGER],
    title: "nav.classes",
    to: { name: "training-class.index" },
  },
  {
    icon: "mdi-book",
    roles: [Role.ADMIN, Role.ACCOUNT_MANAGER],
    title: "nav.bookings",
    to: { name: "booking.index" },
  },
  {
    icon: "mdi-book-edit",
    roles: [Role.ADMIN, Role.ACCOUNT_MANAGER],
    title: "nav.change_requests",
    to: { name: "change-request.index" },
  },
  {
    icon: "mdi-ticket-outline",
    roles: [Role.ADMIN, Role.ACCOUNT_MANAGER],
    title: "nav.tickets",
    to: { name: "ticket.index" },
  },
  {
    icon: "mdi-face-agent",
    roles: [Role.ADMIN, Role.ACCOUNT_MANAGER],
    title: "nav.customers",
    to: { name: "customer.index" },
  },
  {
    group: "admin",
    icon: "mdi-school",
    roles: [Role.ADMIN, Role.ACCOUNT_MANAGER],
    title: "nav.training",
    children: [
      {
        title: "nav.course_list",
        icon: "mdi-view-list",
        to: { name: "course.index" },
      },
      {
        title: "nav.subject_list",
        icon: "mdi-folder-home",
        to: { name: "subject" },
      },
      {
        title: "nav.trainers",
        icon: "mdi-account-tie",
        to: { name: "trainer.index" },
      },
      {
        title: "nav.trainer_courses",
        icon: "mdi-account-star",
        to: { name: "trainer-course.index" },
      },
      {
        title: "nav.public_schedules",
        icon: "mdi-calendar-month",
        to: { name: "public-schedule.index" },
      },
    ],
  },
  {
    group: "admin",
    icon: "mdi-shopping",
    roles: [Role.ADMIN, Role.ACCOUNT_MANAGER],
    title: "nav.purchasing",
    children: [
      {
        title: "nav.suppliers",
        icon: "mdi-store",
        to: { name: "supplier.index" },
      },
      {
        title: "nav.purchase_orders",
        icon: "mdi-cart-variant",
        to: { name: "purchase-order.index" },
      },
    ],
  },
  {
    group: "admin",
    icon: "mdi-printer-pos",
    roles: [Role.ADMIN, Role.ACCOUNT_MANAGER],
    title: "nav.billing",
    children: [
      {
        icon: "mdi-receipt-text-outline",
        title: "nav.invoices",
        to: { name: "invoice.index" },
      },
      {
        icon: "mdi-receipt-text-clock",
        title: "nav.debtors",
        to: { name: "debtor.index" },
      },
    ],
  },
  {
    group: "admin",
    icon: "mdi-email-arrow-right-outline",
    roles: [Role.ADMIN, Role.ACCOUNT_MANAGER],
    title: "nav.email_logs",
    to: { name: "email-log.index" },
  },
  {
    group: "admin",
    icon: "mdi-cog-outline",
    roles: [Role.ADMIN],
    title: "nav.admin",
    children: [
      {
        title: "nav.user_management",
        icon: "mdi-account",
        to: { name: "user.index" },
      },
      {
        title: "nav.workflows",
        icon: "mdi-routes",
        to: { name: "workflow.index" },
      },
      {
        title: "nav.email_templates",
        icon: "mdi-mail",
        to: { name: "email-template.index" },
      },
    ],
  },
];

import store from "@/store";
import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { items } from "@/data/navigation";

export interface ThemeStoreState {
    verticalSidebarMini: boolean;
    appBarColor: string;
    verticalSidebarDrawer: boolean;
    isLoading: boolean;
    fluidContainer: boolean;
    showFooter: boolean;
}

@Module({
    dynamic: true,
    store: store,
    name: "theme",
})
export default class ThemeStore extends VuexModule implements ThemeStoreState {
    verticalSidebarMini = false;
    verticalSidebarDrawer = true;
    appBarColor = "white";
    loading = false;
    saving = false;
    snackBar = "";
    fluidContainer = false;
    showFooter = true;

    get getMenuItems(): Array<any> {
        return items;
    }

    get getThemeMode(): ThemeStoreState {
        return this;
    }

    get isLoading(): boolean {
        return this.loading;
    }

    @Action
    changeVerticalSidebarDrawer(): void {
        this.toggleVerticalSidebarDrawer();
    }

    @Action
    changeVerticalSidebarMini(): void {
        this.toggleVerticalSidebarMini();
    }

    @Mutation
    setVerticalSidebarDrawer(value: boolean): void {
        this.verticalSidebarDrawer = value;
    }

    @Mutation
    toggleVerticalSidebarDrawer(): void {
        this.verticalSidebarDrawer = !this.verticalSidebarDrawer;
    }

    @Mutation
    setVerticalSidebarMini(value: boolean): void {
        this.verticalSidebarMini = value;
    }

    @Mutation
    setFluidContainer(value: boolean): void {
        this.fluidContainer = value;
    }

    @Mutation
    setShowFooter(value: boolean): void {
        this.showFooter = value;
    }

    @Mutation
    toggleVerticalSidebarMini(): void {
        this.verticalSidebarMini = !this.verticalSidebarMini;
    }

    @Mutation
    setLoading(value: boolean): void {
        this.loading = value;
    }

    @Mutation
    setSaving(value: boolean): void {
        this.saving = value;
    }

    @Mutation
    setSnackBar(value: string): void {
        this.snackBar = value;
    }
}

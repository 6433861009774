<template>
  <router-view />
</template>

<script>
import ThemeStore from "@/store/modules/ThemeStore";
import { getModule } from "vuex-module-decorators";

getModule(ThemeStore);

export default {
  name: "App",
  metaInfo() {
    return {
      title: "Systematix",
      titleTemplate: "%s | SX Admin",
    };
  },
  methods: {
    getRouterParams() {
      // console.log(this.$router.currentRoute.query);
    },
  },
  created() {
    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    mq.addEventListener("change", (e) => {
      this.$vuetify.theme.dark = e.matches;
    });
    this.getRouterParams();
  },
};
</script>

import AuthService from "sx-shared-core/src/service/AuthService";
import { User } from "sx-shared-core";

class AdminAuthService extends AuthService {
    validateUserRoles(user: User): boolean {
        return (
            user.roles.includes("ROLE_ADMIN") ||
            user.roles.includes("ROLE_ACCOUNT_MANAGER")
        );
    }
}
export default new AdminAuthService();

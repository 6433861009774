import { extend } from "vee-validate";
import {
    email,
    length,
    max,
    max_value,
    min,
    min_value,
    numeric,
    regex,
    required,
} from "vee-validate/dist/rules";

export default {
    install(): void {
        extend("email", email);
        extend("length", length);
        extend("required", {
            ...required,
            message: "{_field_} field is required",
        });
        extend("min_value", min_value);
        extend("max_value", max_value);
        extend("max", {
            ...max,
            message: "{_field_} field must have at most {length} characters",
        });
        extend("min", min);
        extend("regex", regex);
        extend("numeric", numeric);
        extend("url", {
            validate(value: string): boolean {
                return /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g.test(
                    value
                );
            },
            message: "{_field_} field must be a valid url",
        });

        extend("array_length_max", {
            params: ["length"],
            validate(value: object[], args: Record<string, any>) {
                return value.length <= args.length;
            },
            message: "{_field_} field must not greater than {length}",
        });
    },
};

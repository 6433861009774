import AuthStore from "@/store/modules/AuthStore";
import { Route } from "vue-router";
import { getModule } from "vuex-module-decorators";

const authStore = getModule(AuthStore);

export default async (to: Route, from: Route, next: any) => {
    if (authStore.isAuthenticated) {
        next({ name: "dashboard" });
    }

    next();
};

import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import FontAwesomeIcon from "./plugins/font-awesome";
import { createProvider } from "./vue-apollo";
import i18n from "./i18n";
import theme from "@/plugins/theme";
import VeeValidate from "@/plugins/vee-validate";
import "@/plugins/currency";
import "@/plugins/vue-meta";
import { FormattedDate } from "sx-shared-core";
import VueMask from "v-mask";
Vue.use(VueMask);

import Component from "vue-class-component";

// Register the router hooks with their names
Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate",
]);

Vue.component("FormattedDate", FormattedDate);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(theme);
Vue.use(VeeValidate);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    apolloProvider: createProvider(),
    i18n,
    render: (h) => h(App),
}).$mount("#app");
